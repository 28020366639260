<template>
	<div class="tab-cont__button-cont">
		<button v-for="(tab, index) in tabs" :key="name + '-tab-btn-' + index" class="tab-cont__buttons" :class="{ 'tab-cont__button--clicked' : index === selectedTab }" @click="$emit('selecttab', index)" :aria-label="'Select ' + tab + ' tab'" :data-test="name + '-' + index">
			{{ tab }}
		</button>
	</div>
	<div class="tab-cont__selection-cont" :style="style">
		<div v-for="(tab, index) in tabs" :key="name + '-tab-cont-' + index" class="tab-cont__tab" :class="{ 'tab-cont__tab--clicked' : index === selectedTab}" :data-test="name + '-' + index + '-cont' ">
			<slot :name="'slot-' + index"></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'TabContainer',
	emits: ['selecttab'],
	props: {
		tabs: Array,
		name: String,
		style: Object,
		selectedTab: Number,
	},
}
</script>
<style>
.tab-cont__button-cont {
	height: 3rem;
	width: 100%;
	display: flex;
}

.tab-cont__buttons {
	flex: 1;
	height: 100%;
	position: relative;
	padding: 0 .5rem;

	color: var(--color-font-bg);
	border: none;
	background-color: var(--color-bg-hover);

	line-height: 3rem;
	font-weight: 700;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	transition: font ease .1s;
}

.tab-cont__buttons:focus,
.tab-cont__buttons:hover {
	border-bottom: .05rem solid var(--color-primary);
}

@media(max-width: 500px) {

	.tab-cont__buttons:focus,
	.tab-cont__buttons:hover {
		border: none;
	}
}

.tab-cont__button--clicked {
	color: var(--color-primary);
	background-color: var(--color-bg);
}

.tab-cont__button--clicked:focus,
.tab-cont__button--clicked:hover {
	border: none;
}

.tab-cont__buttons::after {
	content: '';
	width: 0rem;
	height: 100%;
	margin: none;
	padding: none;
	position: absolute;
	right: 0;
	top: 0;

	background-color: var(--color-primary);

	transition: .3s;
}

.tab-cont__button--clicked::after {
	width: .25rem;
	transition: .25s ease;

}

.tab-cont__tab {
	height: 100%;
	width: 100%;
	display: none;
}

.tab-cont__tab--clicked {
	display: flex;
}

.tab-cont__selection-cont {
	flex: 1;
	height: 100%;
	background-color: transparent;
	overflow: hidden;
	overflow-y: auto;
}
</style>
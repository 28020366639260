<template>
	<div id="main-cont" class="container-main">
		<aside class="main-sidebar flex-column">
			<!-- Show a table container with a tab for each bike -->
			<TabContainer v-if="sideBarTabs.length > 0" :tabs="sideBarTabs" name="sidebar-tab" :selectedTab="bikeData.selectedBike.value" @selecttab="bikeData.changeBike">
				<template v-for=" (tab, idx) in sideBarTabs" v-slot:[tabSlot(idx)] :key="'slot-content-' + idx">
					<div class="sidebar-content">
						<TabMod :index="idx" />
						<TabBike :index="idx" />
					</div>
				</template>
			</TabContainer>
			<!-- Show only add bike button if none are loaded -->
			<div v-else class="flex-1 flex-column flex-center">
				<ButtonStandard name="New" @click="bikeData.createBike" test="first-bike-btn" />
			</div>
		</aside>
		<div class="flex-1 flex-column no-overflow">
			<KonvaPlot />
			<footer>
				By using this app you agree to our <a :href="siteURL + '/privacy'" target="_blank">Privacy Policy</a> and <a :href="siteURL + '/terms'" target="_blank">Terms and Conditions</a>.
			</footer>
		</div>
	</div>
</template>
<script>
import TabContainer from "@/components/layout/TabContainer.vue";
import ButtonStandard from "@/components/control/ButtonStandard.vue";

import TabBike from "@/components/TabBike.vue";
import TabMod from "@/components/TabMod.vue";
import KonvaPlot from "@/components/KonvaPlot.vue";

export default {
	name: 'App',

	inject: ['bikeData'],

	components: {
		ButtonStandard,
		TabContainer,
		TabBike,
		TabMod,
		KonvaPlot,
	},

	computed: {
		siteURL: function() { return process.env.VUE_APP_MAIN_SITE; },
		sideBarTabs: function() { return this.bikeData.bikeList.map(bike => bike.name); }
	},

	methods: {
		tabSlot: function(idx) { return 'slot-' + idx; },
	}
}
</script>
<style>
#app {
	/*Single Page App Base Styling*/
	width: 100%;
	height: 100%;
	min-width: 100vw;
	min-height: 100vh;
	max-width: 100vw;
	max-height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

/*Allow overflow of SPA container on mobile*/
@media(max-width: 1000px) {
	#app {
		display: block;
		width: 100%;
		max-height: default;
		overflow-y: auto;
		overflow-x: hidden;
		flex-direction: column;
	}

	#app>.container-main {
		flex-direction: column
	}
}


.main-sidebar {
	width: 65rem;
	background-color: var(--color-bg);
	border-right: .1rem solid var(--color-bg-secondary-light);
}

.sidebar-content {
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: row;
}

@media(max-width: 630px) {
	.sidebar-content {
		flex-direction: column;
	}
}

@media(max-width: 1000px) {
	.main-sidebar {
		max-width: 100%;
		min-height: 20rem;
		width: 100%;
		border: none;
		border-bottom: .1rem solid var(--color-bg-secondary-light);
	}
}

footer {
	height: 3rem;
	line-height: 3rem;
	font-size: 1rem;
}

footer a {
	font-size: 1rem;
	text-decoration: underline;
}
</style>
import { sinD, cosD, tanD, round, copy } from "@/modules/utility.js"

//----------------------------------------------------------------------------
//POINT HANDLING FUNCTIONS----------------------------------------------------
export function magnitude(p1, p2) {
	return Math.sqrt((p1[0] - p2[0]) ** 2 + (p1[1] - p2[1]) ** 2);
}

//Subtract two points element wise
export function subLi(p1, p2) { return [p1[0] - p2[0], p1[1] - p2[1]]; }

export function theta_x(p1, p2) {
	let r = magnitude(p1, p2);
	let theta = NaN;
	if (r !== 0) {
		theta = Math.acos((p2[0] - p1[0]) / r);
	} else {
		theta = NaN;
	}
	// check if angle is in 3rd or 4th quadrant and adjust and return
	// output range must be -pi to pi to match the 2*atan(x) function 
	// that is used to get link angles
	if (p2[1] < p1[1]) {
		theta = -theta;
	}
	return theta;
}

//Rotate point about point
export function rotPoint(p1, p_axis, theta) {
	const v = [p1[0] - p_axis[0], p1[1] - p_axis[1]];
	return [
		v[0] * Math.cos(theta) - v[1] * Math.sin(theta) + p_axis[0],
		v[0] * Math.sin(theta) + v[1] * Math.cos(theta) + p_axis[1]
	];
}

//Get the shortest distance from point to a line
export function pointToLine(p1, p2, p3) {
	//Finds distance from p3 to a line of p1-p2
	//uses formula from http://paulbourke.net/geometry/pointlineplane/

	//check for coincident points
	if (p1[0] == p2[0] && p1[1] == p2[1]) {
		return magnitude(p3, p2);
	} else {
		const segLength = magnitude(p1, p2);
		const u = ((p3[0] - p1[0]) * (p2[0] - p1[0]) + (p3[1] - p1[1]) * (p2[1] - p1[1])) /
			(segLength * segLength);
		const p_n = [p1[0] + u * (p2[0] - p1[0]), p1[1] + u * (p2[1] - p1[1])];
		return magnitude(p3, p_n)
	}
}

// Common tangent of two circles
export function commonTan(c1, c2, r1, r2) {
	// Upper/Right external common tangent from circle 1 to circle 2
	// C1 should be left/above of C2 for upper/right
	// pt1 is on circle 1 and pt2 is on circle2
	const c_gamma = Math.atan2(c2[1] - c1[1], c2[0] - c1[0]);

	const c_beta = Math.asin((r1 - r2) / magnitude(c2, c1));

	const c_alpha = c_gamma - c_beta;

	const x3 = c1[0] - r1 * Math.cos(Math.PI / 2 - c_alpha);
	const y3 = c1[1] + r1 * Math.sin(Math.PI / 2 - c_alpha);
	const x4 = c2[0] - r2 * Math.cos(Math.PI / 2 - c_alpha);
	const y4 = c2[1] + r2 * Math.sin(Math.PI / 2 - c_alpha);

	const pt1 = [x3, y3];
	const pt2 = [x4, y4];
	return [pt1, pt2];
}


//----------------------------------------------------------------------------
//BIKE GEOMETRY FUNCTIONS-----------------------------------------------------

export function geoForkRise(totalLength, offset, hta) {
	const drop = sinD(90 - hta) * offset;
	const rise = sinD(hta) * totalLength;
	return rise - drop;
}

export function geoTrail(wheelDia, offset, hta) {
	const x_offset = cosD(90 - hta) * offset;
	const y_offset = sinD(90 - hta) * offset;
	return 1 / tanD(hta) * (wheelDia / 2 - y_offset) - x_offset;
}

export function geoForkRun(totalLength, offset, hta) {
	const lower = cosD((90 - hta)) * offset;
	const upper = cosD(hta) * totalLength;
	return lower + upper;
}

export function geoSeatedFit(reach, stack, hta, stemLength, totalSpacers, barHeight, sta, nsh) {
	const x_bar = reach - cosD(hta) * totalSpacers + cosD(90 - hta) * stemLength;
	const y_bar = stack + sinD(hta) * totalSpacers + sinD(90 - hta) * stemLength + barHeight;

	const x_saddle = -cosD(sta) * nsh;
	const y_saddle = sinD(sta) * nsh;
	return magnitude([x_bar, y_bar], [x_saddle, y_saddle])
}

export function geoTotalReachStack(reach, stack, hta, stemLength, totalSpacers, barHeight) {
	const x_bar = reach - cosD(hta) * totalSpacers + cosD(90 - hta) * stemLength;
	const y_bar = stack + sinD(hta) * totalSpacers + sinD(90 - hta) * stemLength + barHeight;

	return [x_bar, y_bar]
}

export function geoHTT(reach, stack, sta) {
	const x_st = stack / tanD(sta);
	return x_st + reach;
}

//----------------------------------------------------------------------------
//MAIN GEO COMPUTE FUNCTIONS--------------------------------------------------

export function computeGeo(geo) {
	geo.cs = magnitude([0, 0], [-geo.rc, geo.bbDrop]);
	geo.bbHeight = geo.rearTire / 2 - geo.bbDrop;

	geo.stack = geoForkRise(geo.forkLength + geo.lowerHeadset + geo.htl, geo.offset, geo.hta) +
		(geo.frontTire - geo.rearTire) / 2 + geo.bbDrop;

	geo.seatedFit = geoSeatedFit(geo.reach, geo.stack, geo.hta, geo.stemLength,
		geo.stemSpacers + geo.upperHeadset + geo.stemStack, geo.barHeight, geo.sta_eff, geo.nsh);

	const totalSpacers = geo.upperHeadset + geo.stemSpacers + geo.stemStack;
	[geo.totalReach, geo.totalStack] = geoTotalReachStack(geo.reach, geo.stack, geo.hta, geo.stemLength,
		totalSpacers, geo.barHeight)

	geo.bbToBar = (geo.totalReach ** 2 + geo.totalStack ** 2) ** .5

	geo.fc = geoForkRun(geo.forkLength + geo.lowerHeadset + geo.htl, geo.offset, geo.hta) + geo.reach;
	geo.wheelbase = geo.rc + geo.fc;

	geo.trail = geoTrail(geo.frontTire, geo.offset, geo.hta);
	geo.htt = geoHTT(geo.reach, geo.stack, geo.sta_eff);

	geo.bbDropTotal = pointToLine(
		[-geo.rc, geo.rearTire / 2 - geo.bbHeight],
		[geo.fc, geo.frontTire / 2 - geo.bbHeight],
		[0, 0]);

	for (let item in geo) { geo[item] = round(geo[item], 1); }
	return geo;
}

//Compute new geo with chip overrides
export function computeModGeo(geo_nom, modifiers) {
	const geo = copy(geo_nom); //Create deep copy to override with new geo

	//Chip list required because some parameters (tires) are added differently
	const chipList = ['stemLength', 'stemSpacers', 'barHeight', 'lowerHeadset', 'hta', 'reach', 'forkLength', 'offset'];

	//Add offsets to geo for every size
	chipList.forEach(key => { geo[key] += modifiers[key]; });

	const totalForkLength = geo.htl + geo.lowerHeadset + geo.forkLength;

	//Need headtube top, front wheel, and saddle location to compute all other geo
	let p_ht = [geo.reach, geo.stack]; //Geo has already been computed

	let p_frontWheel = [
		p_ht[0] + geoForkRun(totalForkLength, geo.offset, geo.hta),
		p_ht[1] - geoForkRise(totalForkLength, geo.offset, geo.hta)
	];

	let p_rearWheel = [-geo.rc, geo.bbDrop];

	let p_saddle = [
		geo.bbOffset - cosD(geo.sta_eff) * geo.nsh,
		sinD(geo.sta_eff) * geo.nsh
	];

	//Override tire radius if chipConfig paramter is provided
	geo.frontTire = modifiers.frontTire ? modifiers.frontTire : geo.frontTire;
	geo.rearTire = modifiers.rearTire ? modifiers.rearTire : geo.rearTire;

	const r_tire_front = geo.frontTire / 2;
	const r_tire_rear = geo.rearTire / 2;

	const [wb_f, wb_r] = commonTan(p_frontWheel, p_rearWheel, r_tire_front, r_tire_rear);

	const wb_angle = theta_x(wb_r, wb_f);
	const wb_angle_deg = wb_angle * 180 / Math.PI;

	//Rotate head tube point and get distance from BB for reach/stack
	p_ht = rotPoint(p_ht, [0, 0], -wb_angle);

	//Rotate rear wheel point and get distance from BB for rear center
	p_rearWheel = rotPoint(p_rearWheel, [0, 0], -wb_angle);

	//Rotate front wheel point to get front center
	p_frontWheel = rotPoint(p_frontWheel, [0, 0], -wb_angle);

	//When bb is shifted the effective STA can change from stock config
	const sta_bb_shifted = 180 - theta_x([0, 0], p_saddle) * 180 / Math.PI;
	const sta_change = sta_bb_shifted - geo.sta_eff + wb_angle_deg;

	geo.wb_angle = -wb_angle; //Reverse to rotate points in correct dir
	geo.wheelbase = magnitude(wb_f, wb_r);
	geo.reach = p_ht[0];
	geo.stack = p_ht[1];
	geo.sta_eff += sta_change;
	geo.sta_act += wb_angle_deg;
	geo.hta += wb_angle_deg;

	geo.bbHeight = pointToLine(wb_f, wb_r, [0, 0]);
	geo.bbDropTotal = pointToLine(p_frontWheel, p_rearWheel, [0, 0]);

	geo.bbDrop = r_tire_rear - geo.bbHeight;

	geo.rc = Math.abs(p_rearWheel[0]);
	geo.fc = p_frontWheel[0];

	geo.trail = geoTrail(r_tire_front * 2, geo.offset, geo.hta);
	geo.htt = geoHTT(geo.reach, geo.stack, geo.sta_eff);

	const totalSpacers = geo.upperHeadset + geo.stemSpacers + geo.stemStack;
	geo.seatedFit = geoSeatedFit(geo.reach, geo.stack,
		geo.hta, geo.stemLength, totalSpacers, geo.barHeight,
		geo.sta_eff, geo.nsh);

	[geo.totalReach, geo.totalStack] = geoTotalReachStack(geo.reach, geo.stack,
		geo.hta, geo.stemLength, totalSpacers, geo.barHeight);

	geo.bbToBar = (geo.totalReach ** 2 + geo.totalStack ** 2) ** .5
	for (let item in geo) { geo[item] = round(geo[item], 1); }
	return geo;
}

export function computeFramePoints(geo) {
	const cos_hta = cosD(geo.hta);
	const sin_hta = sinD(geo.hta);

	const fw = [geo.fc, geo.bbDrop + (geo.frontTire - geo.rearTire) / 2]; //Save front wheel points for chip
	// const fw = this.chipData.points[19]; //Save front wheel points

	let htTop = [geo.reach + geo.bbOffset, geo.stack];

	let htBottom = [];
	htBottom[0] = htTop[0] + cos_hta * geo.htl;
	htBottom[1] = htTop[1] - sin_hta * geo.htl;

	let lcsBottom = [];
	lcsBottom[0] = htBottom[0] + cos_hta * geo.lowerHeadset;
	lcsBottom[1] = htBottom[1] - sin_hta * geo.lowerHeadset;

	let forkBottom = [];
	forkBottom[0] = lcsBottom[0] + cos_hta * geo.forkLength;
	forkBottom[1] = lcsBottom[1] - sin_hta * geo.forkLength;

	let saddle = [];
	saddle[0] = geo.bbOffset - cosD(geo.sta_eff) * geo.nsh;
	saddle[1] = sinD(geo.sta_eff) * geo.nsh;

	//NOTE we will consider the distance from saddle to top of ST on actual line
	//This will introduce some error as actual and effective STA differ
	let stTop = [];
	stTop[0] = saddle[0] + cosD(geo.sta_act) * (geo.nsh - geo.stl);
	stTop[1] = saddle[1] - sinD(geo.sta_act) * (geo.nsh - geo.stl);

	let stMid = [];
	geo.insertion = .6 * geo.stl; //Default to 60% of stl
	stMid[0] = stTop[0] + cosD(geo.sta_act) * (geo.insertion);
	stMid[1] = stTop[1] - sinD(geo.sta_act) * (geo.insertion);

	const totalStack = geo.stemSpacers + geo.upperHeadset + geo.stemStack;

	let stemMid = []
	stemMid[0] = htTop[0] - cos_hta * totalStack;
	stemMid[1] = htTop[1] + sin_hta * totalStack;

	let barCenter = []
	barCenter[0] = stemMid[0] + cosD(90 - geo.hta) * geo.stemLength;
	barCenter[1] = stemMid[1] + sinD(90 - geo.hta) * geo.stemLength;

	let handPos = [barCenter[0], barCenter[1] + geo.barHeight];

	const bb = [0, 0];
	const rw = [-geo.rc, geo.bbDrop];
	return {
		bb,
		rw,
		handPos,
		barCenter,
		stemMid,
		htTop,
		htBottom,
		lcsBottom,
		forkBottom,
		saddle,
		stTop,
		stMid,
		fw
	}
}
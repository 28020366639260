<template>
	<div class="md-textfield" :style="{margin: margin, maxWidth: width}">
		<input class="md-textfield__input" :data-test="test" :is-empty="value === '' ? 'true' : 'false'" :type="type ? type : 'text'" :autocomplete="autocomplete ? autocomplete : 'disable'" :value="value" :disabled="disabled" :class="{'md-textfield-error' : error}" @blur="$emit('submit', $event.target.value.trim())" @keydown.enter="$emit('submit', $event.target.value.trim())" @keyup.enter="$emit('submit', $event.target.value.trim())" @input="$emit('input', $event.target.value)" />
		<label class="md-textfield__label">{{ name }}
		</label>
		<div class="md-textfield__icon">
			<slot></slot>
		</div>
	</div>
</template>
<script>
export default {
	name: 'TextInputMD',
	emits: ['submit', 'input'],
	props: {
		margin: String,
		width: String,
		name: String,
		value: [Number, String],
		type: String,
		disabled: Boolean,
		error: Boolean,
		autocomplete: String,
		test: String,
	},
}
</script>
<!-- Item has optional listeners for blur and input. Blur event will also trigger on enter for a batch submission of the current text content -->
<style>
.md-textfield {
	display: block;
	height: 4rem;
	flex: 1;
	position: relative;

	margin: 1.5rem .02rem;
}

.md-textfield__icon {
	position: absolute;
	top: -.5rem;
	right: 1rem;
	height: 1rem;
	width: 1rem;
}

.md-textfield:first-child {
	margin-top: 1rem;
}

.md-textfield:last-child {
	margin-bottom: 1rem;
}

.md-textfield__input {
	appearance: none;

	height: 3rem;
	width: 100%;
	position: relative;
	z-index: 1;

	padding: .2rem 1.0rem;
	margin-top: 1rem;

	background-color: transparent;
	border: 0.1rem solid var(--color-bg-secondary-light);
	border-radius: .2rem;

	line-height: 3rem;
	transition: .3s;
}

.md-textfield__input:disabled {
	background-color: var(--color-bg-cell-highlight);
}

.md-textfield__input:focus {
	outline: none;
	border-color: var(--color-primary);
	box-shadow: 0 0 0 3px var(--color-primary-light);
}

.md-textfield-error {
	border-color: var(--color-error);
}

.md-textfield-error:focus {
	border-color: var(--color-error);
	box-shadow: 0 0 0 3px var(--color-error-light);
}

.md-textfield__label {
	color: var(--font-bg);
	background-color: transparent;
	left: .5rem;
	padding: 0 .3rem;
	position: absolute;
	top: 1.75rem;
	transition: top .1s ease-in 0s, left .1s ease-in 0s;
}

.md-textfield__input:focus+.md-textfield__label,
.md-textfield__input[is-empty="false"]+.md-textfield__label {
	color: var(--color-primary);
	font-size: 1.1rem;
	font-weight: 700;
	top: -.5rem;
	left: 0rem;
	background: transparent;
	z-index: 2;
}

.md-textfield__input:not(:focus)+.md-textfield__label {
	color: var(--color-font-bg);
}

.md-textfield-error:focus+.md-textfield__label,
.md-textfield-error[is-empty="false"]+.md-textfield__label,
.md-textfield-error:not(:focus)+.md-textfield__label {
	color: var(--color-error);
	border-color: var(--color-error)
}
</style>
<template>
	<InputTable :name="'bike-table-' + index" :compact="true" :tableSetup="geoTable" :headerRow=" ['', 'Nom.', 'Mod.']" @change="handleTable" labelWidth="12rem" :tableStyle="{marginLeft: '1rem'}" />
</template>
<script>
import InputTable from "@/components/control/InputTable.vue";

import { computed, inject } from "vue";

export default {
	name: 'TabBike',
	components: {
		InputTable,
	},
	props: {
		index: Number,
	},

	setup(props) {
		const tableRows = {
			'reach': { displayName: 'Reach', active: false },
			'hta': { displayName: 'Head Tube Angle', active: false },
			'htl': { displayName: 'Heat Tube Length', active: false },
			'forkLength': { displayName: 'Fork Length', active: false },
			'offset': { displayName: 'Offset', active: false },
			'trail': { displayName: 'Trail', active: true },
			'lowerHeadset': { displayName: 'Lower Headset', active: false },
			'stack': { displayName: 'Stack', active: true },
			'htt': { displayName: 'Horz. Top Tube', active: true },
			'fc': { displayName: 'Front Center', active: true },
			'rc': { displayName: 'Rear Center', active: false },
			'cs': { displayName: 'Chainstay Length', active: true },
			'wheelbase': { displayName: 'Wheelbase', active: true },
			'frontTire': { displayName: 'F. Tire Diameter', active: false },
			'rearTire': { displayName: 'R. Tire Diameter', active: false },
			'bbDrop': { displayName: 'BB Drop', active: false },
			'bbDropTotal': { displayName: 'BB Drop Total', active: true },
			'bbHeight': { displayName: 'BB Height', active: true },
			'sta_eff': { displayName: 'Seat Angle Eff.', active: false },
			'sta_act': { displayName: 'Seat Angle Act.', active: false },
			'stl': { displayName: 'Seat Tube Length', active: false },
			'nsh': { displayName: 'Nom. Seat Height', active: false },
			'upperHeadset': { displayName: 'Upper Headset', active: false },
			'stemLength': { displayName: 'Stem Length', active: false },
			'stemSpacers': { displayName: 'Stem Spacers', active: false },
			'barHeight': { displayName: 'Bar Height', active: false },
			'totalReach': { displayName: 'Total Reach', active: true },
			'totalStack': { displayName: 'Total Stack', active: true },
			'bbToBar': { displayName: 'BB to Bar (RAD)', active: true },
			'seatedFit': { displayName: 'Seated Fit', active: true },
		}

		const bikeData = inject('bikeData');
		const bike = bikeData.bike(props.index);

		const rowKeys = Object.keys(tableRows); //Create ordered list of rows

		const geoTable = computed(() => {
			return rowKeys.map(key => {
				return [
					tableRows[key].displayName,
					[bike.geometry[key], bike.geo_mod[key]], //Row Values
					true, //Display true
					tableRows[key].active, //Disable inputs
					[false, true] //Disable select inputs
				]
			})
		});

		//Handle Table inputs and assign to store
		function handleTable(row, column, value, prev) {
			value = parseFloat(value);
			value = isNaN(value) ? prev : value;
			bikeData.setBikeData({
				index: props.index,
				item: 'geometry',
				subItem: rowKeys[row],
				value: value,
			});
		}

		return { geoTable, handleTable }
	},
}
</script>
export function sinD(theta) { return Math.sin(theta * Math.PI / 180); }

export function cosD(theta) { return Math.cos(theta * Math.PI / 180); }

export function tanD(theta) { return Math.tan(theta * Math.PI / 180); }

// Round to a set number of decimal places
export function round(value, precision) {
	const mult = 10 ** precision;
	return Math.round(mult * value) / mult;
}

export function copy(item) { return JSON.parse(JSON.stringify(item)); }
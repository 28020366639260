export class DefaultGeo {
	constructor(name) {
		this.name = name;
		this.geo_mod = {};
		this.geometry = {
			reach: 475,
			hta: 64.0,
			htl: 110.0,
			forkLength: 581.0,
			offset: 44.0,
			lowerHeadset: 3.0,
			trail: 0.0,
			htt: 0.0,

			stack: 0.0,
			fc: 0.0,
			rc: 430.0,
			cs: 0.0,
			wheelbase: 0.0,

			sta_eff: 76,
			sta_act: 74,
			stl: 410,

			nsh: 785,
			insertion: 250,

			frontTire: 750,
			rearTire: 750,
			bbDrop: 15.0,
			bbDropTotal: 10,
			bbHeight: 0.0,
			bbOffset: 0.0,

			upperHeadset: 5.0,
			stemLength: 40.0,
			stemSpacers: 10.0,
			stemStack: 20.0,
			barHeight: 25.0,
			seatedFit: 0.0,

			totalReach: 0,
			totalStack: 0,

			riderHeight: 1790.0,
			riderWeight: 735.0,
			cogX: 0.0,
			cogY: 600.0,
		};

		this.modifiers = {
			rearTire: 0,
			frontTire: 0,

			stemLength: 0,
			stemSpacers: 0,
			barHeight: 0,
			lowerHeadset: 0,
			hta: 0,
			reach: 0,

			forkLength: 0,
			offset: 0,
		};
	}
}
import { createApp } from "vue";
import App from "./App.vue";

import "@/assets/css/normalize.css";
import "@/assets/css/typography.css";
import "@/assets/css/style.css";
import "@/assets/css/tooltip.css";
import "@/assets/css/component-style.css";
import "@/assets/css/vue-transitions.css";

import VueKonva from 'vue-konva';

import bikeData from "@/modules/bikeData.js";

import { VTooltip } from "v-tooltip";
import router from './router'

VTooltip.options.defaultDelay = 300;
VTooltip.options.defaultHideOnTargetClick = false;
VTooltip.options.html = false;

const vueApp = createApp(App)
	.use(router)
	.use(VueKonva)
	.provide('bikeData', bikeData)
	.directive('tooltip', VTooltip)
	.mount('#app')

window.bikeData = bikeData
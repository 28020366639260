<template>
	<div class="tab-mod scroll-y flex-column flex-space">
		<div>
			<TextInputMD name="Name" :value="bike.name" @submit="setName" />
			<TextInputMD v-for="(key, idx) in keyList" :name="labelList[idx]" :key="key" :value="bike.modifiers[key]" @submit="setParam(key, $event)">
				<InfoIcon size="1.2rem" :msg="infoList[idx]">
				</InfoIcon>
			</TextInputMD>
		</div>
		<div class="multi-button-cont">
			<ButtonStandard name="New" @click="bikeData.createBike" test="new-bike-btn" />
			<ButtonStandard name="Copy" @click="bikeData.copyBike" test="copy-bike-btn" />
			<ButtonStandard name="Delete" :error="true" :clear="true" @click="bikeData.deleteBike(index)" />
		</div>
	</div>
</template>
<script>
import TextInputMD from "@/components/control/TextInputMD.vue";
import ButtonStandard from "@/components/control/ButtonStandard.vue";
import InfoIcon from "@/components/InfoIcon.vue";

export default {
	name: 'TabMod',
	inject: ['bikeData'],
	props: {
		index: Number,
	},
	components: {
		TextInputMD,
		ButtonStandard,
		InfoIcon
	},

	data() {
		return {
			keyList: [
				'frontTire', 'rearTire', 'forkLength', 'offset',
				'hta', 'reach', 'lowerHeadset',
				'stemLength', 'stemSpacers', 'barHeight',
			],

			labelList: [
				'F. Tire Diameter', 'R. Tire Diameter', 'Fork Length Change', 'Fork Offset Change',
				'Head Tube Angle Change', 'Reach Adjust', 'Lower Headset Stack Change',
				'Stem Length Change', 'Stem Spacer Change', 'Bar Height Change',
			],

			infoList: [
				`New front tire diameter in mm. Set to 0 to use original tire.<br><br>Some good starting points are: <br> 	<table style="width: 100%">
		<tr><td>      </td><td>24"</td><td>26"</td><td>27.5"</td><td>29"</td>	</tr>
		<tr><td><b>2.0</td><td>609</td><td>661</td><td>686</td><td>724</td></tr>
		<tr><td><b>2.1</td><td>614</td><td>665</td><td>691</td><td>729</td></tr>
		<tr><td><b>2.2</td><td>619</td><td>671</td><td>696</td><td>734</td></tr>
		<tr><td><b>2.3</td><td>624</td><td>676</td><td>701</td><td>739</td></tr>
		<tr><td><b>2.4</td><td>629</td><td>681</td><td>706</td><td>744</td></tr>
		<tr><td><b>2.5</td><td>634</td><td>686</td><td>711</td><td>749</td></tr>
		<tr><td><b>2.6</td><td>639</td><td>691</td><td>716</td><td>754</td></tr>
		<tr><td><b>2.8</td><td>646</td><td>701</td><td>726</td><td>764</td></tr>
		<tr><td><b>3.0</td><td>659</td><td>711</td><td>736</td><td>774</td></tr>
	</table>`,
				`New rear tire diameter in mm. Set to 0 to use original tire.<br> Some good starting points are: <br> 	<table style="width: 100%">
		<tr><td>      </td><td>24"</td><td>26"</td><td>27.5"</td><td>29"</td>	</tr>
		<tr><td><b>2.0</td><td>609</td><td>661</td><td>686</td><td>724</td></tr>
		<tr><td><b>2.1</td><td>614</td><td>665</td><td>691</td><td>729</td></tr>
		<tr><td><b>2.2</td><td>619</td><td>671</td><td>696</td><td>734</td></tr>
		<tr><td><b>2.3</td><td>624</td><td>676</td><td>701</td><td>739</td></tr>
		<tr><td><b>2.4</td><td>629</td><td>681</td><td>706</td><td>744</td></tr>
		<tr><td><b>2.5</td><td>634</td><td>686</td><td>711</td><td>749</td></tr>
		<tr><td><b>2.6</td><td>639</td><td>691</td><td>716</td><td>754</td></tr>
		<tr><td><b>2.8</td><td>646</td><td>701</td><td>726</td><td>764</td></tr>
		<tr><td><b>3.0</td><td>659</td><td>711</td><td>736</td><td>774</td></tr>
	</table>`,
				'Increase or decrease in fork length from nominal',
				'Increase or decrease in fork offset from nominal',
				'Change in head angle in degrees from nominal. Note that the advertised change for an angleset may not represent the actual increase or decrease in fork angle from nominal but instead represents the final result on the geometry. For example some anglesets will tilt the fork by a certain angle and use an increase in lower headset stack to effect the angle further.',
				'Increase or decrease in reach such as from a reach change headset. Assumes purely horizontal movement.',
				'Increase or decrease in lower headset stack such as from an extended crown race or changed from an internal to external cup (anglesets that change from an internal to an external lower cup need this value to be set).',
				'Increase or decrease in stem length. For example use -10mm to model a change from a 50mm stem to a 40mm stem.',
				'Increase or decrease in the total stem spacer height under the stem from nominal.',
				'Increase or decrease in bar height (for example enter +5mm to model the change from a 20mm rise to a 25mm rise bar)',
			]
		}
	},


	computed: {
		bike: function() { return this.bikeData.bike(this.index); }
	},

	methods: {
		setName: function(name) {
			this.bikeData.setBikeData({
				index: this.index,
				item: 'name',
				value: name,
			});
		},

		setParam: function(key, value) {
			value = parseFloat(value);
			value = isNaN(value) ? 0 : value
			value = ['frontTire', 'rearTire'].includes(key) && value < 0 ? 0 : value;
			this.bikeData.setBikeData({
				index: this.index,
				item: 'modifiers',
				subItem: key,
				value: value,
			})
		}
	},


}
</script>
<style>
.tab-mod {
	min-width: 27rem;
	padding: 1rem;
	padding-right: 1rem;
}
</style>
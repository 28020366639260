<template>
	<v-stage id="konva-plot" class="flex-1" ref="konva" :config="stage.config">
		<v-layer>
			<v-circle v-for="(wheel, idx) in wheels" :key="'wheel' + idx" :config="wheel" />
			<v-line :config="ft" />
			<v-line :config="rt" />
			<v-line :config="fork" />
			<v-line :config="seatAngle" />
			<v-circle v-for="(point, idx) in konvaPoints" :key="'frame-point-' + idx" :config="point" />
		</v-layer>
		<v-layer>
			<v-circle v-for="(wheel, idx) in modWheels" :key="'wheel' + idx" :config="wheel" />
			<v-line :config="modFt" />
			<v-line :config="modRt" />
			<v-line :config="modFork" />
			<v-line :config="modSeatAngle" />
			<v-circle v-for="(point, idx) in modKonvaPoints" :key="'frame-point-' + idx" :config="point" />
		</v-layer>
	</v-stage>
</template>
<script>
import { KonvaStage } from "@/modules/konvaUtilities.js";
import { computeFramePoints } from "@/modules/geometry.js";
import { ref, reactive, onMounted, onBeforeUnmount, inject, computed, watchEffect } from "vue";

const nomColor = '#BEBEBE';
const modColor = '#720793';
const wheelColor = '#777777';
const modWheelColor = '#242424';
const modPointColor = '#55066E';

export default {
	name: 'KonvaPlot',

	setup() {
		const konva = ref(null);
		const stage = new KonvaStage(konva);

		const bikeData = inject('bikeData');
		const bike = bikeData.currentBike; //Computed

		let isSmall = false;

		function resize(evt) {
			const box = {
				top: -800,
				left: -450 - 375,
				right: 900 + 375,
				bottom: 375,
			}
			stage.boxCenter(box, .9);

			if (window.innerWidth < 1000) {
				console.log(window.innerWidth)

				stage.removeZoom();
				isSmall = true;
			} else {
				if (isSmall) {
					stage.addZoom();
					isSmall = false;
				}

			}
		}

		onMounted(() => {
			stage.element = document.getElementById('konva-plot');
			stage.makeResponsive();

			if (window.innerWidth >= 1000) { stage.addZoom(); }

			const box = {
				top: -800,
				left: -450 - 375,
				right: 900 + 375,
				bottom: 375,
			}
			stage.boxCenter(box, .9);

			window.onresize = resize;
		});
		onBeforeUnmount(() => {
			stage.stopKonva();
			window.onresize = () => {};
		});

		//--------------------------------------------------------------------
		//Compute FT main shape-----------------------------------------------

		function getPoints(geo_spec) {
			if (!bike.value || !konva.value) { return false; }
			const points = computeFramePoints(bike.value[geo_spec]);
			const y_offset = geo_spec === 'geo_mod' ? -bike.value.geometry.bbHeight + bike.value.geo_mod.bbHeight : 0;

			for (const key in points) { points[key][1] = -points[key][1] - y_offset; }

			return points;
		}

		function getKonvaPoints(framePoints, color) {
			const points = [];
			for (const key in framePoints) { points.push(stage.pointConfig(framePoints[key], color)); }

			return points;
		}

		const blak = 'asd'

		//Get frame points for nominal geo
		const framePoints = computed(() => { return getPoints('geometry'); });
		const modFramePoints = computed(() => { return getPoints('geo_mod') });

		const konvaPoints = computed(() => { return getKonvaPoints(framePoints.value, nomColor); });
		const modKonvaPoints = computed(() => { return getKonvaPoints(modFramePoints.value, modPointColor) });

		function getWheels(geo_spec, points, color) {
			if (!bike.value || !konva.value) { return []; }
			const geo = bike.value[geo_spec];

			return [
				stage.wheelConfig(points.fw, geo.frontTire / 2, color),
				stage.wheelConfig(points.rw, geo.rearTire / 2, color)
			];
		}

		const wheels = computed(() => { return getWheels('geometry', framePoints.value, wheelColor); });
		const modWheels = computed(() => { return getWheels('geo_mod', modFramePoints.value, modWheelColor); });


		const ftPointList = ['handPos', 'barCenter', 'stemMid', 'htTop', 'htBottom', 'bb', 'stMid', 'stTop', 'htTop'];
		const rtPointList = ['stMid', 'rw', 'bb'];
		const forkPointList = ['htBottom', 'lcsBottom', 'forkBottom', 'fw']

		//Generate points and lines for a given frame point set
		const createShape = (points, pointList, color, dash = false) => {
			if (!points || !konva.value) { return false; }

			const lines = pointList.map(key => {
				return points[key];
			});

			return stage.lineConfig(lines, color, dash);
		};

		const ft = computed(() => { return createShape(framePoints.value, ftPointList, nomColor); })
		const rt = computed(() => { return createShape(framePoints.value, rtPointList, nomColor); })
		const fork = computed(() => { return createShape(framePoints.value, forkPointList, nomColor); })
		const seatAngle = computed(() => {
			return createShape(framePoints.value, ['bb', 'saddle', 'stTop'], nomColor, [7, 7]);
		});

		const modFt = computed(() => { return createShape(modFramePoints.value, ftPointList, modColor); })
		const modRt = computed(() => { return createShape(modFramePoints.value, rtPointList, modColor); })
		const modFork = computed(() => { return createShape(modFramePoints.value, forkPointList, modColor); })
		const modSeatAngle = computed(() => {
			return createShape(modFramePoints.value, ['bb', 'saddle', 'stTop'], modColor, [7, 7]);
		});

		return {
			konva,
			stage,
			wheels,
			modWheels,
			konvaPoints,
			modKonvaPoints,
			ft,
			rt,
			fork,
			seatAngle,
			modFt,
			modRt,
			modFork,
			modSeatAngle,
		}
	},
};
</script>
<style>
#konva-plot {
	flex: 1;
	max-height: auto;
	min-height: auto;
	overflow: hidden;
}

@media(max-width: 1000px) {
	#konva-plot {
		min-height: 100vw;
		max-height: 100vw;
	}
}
</style>
<template>
	<nav class="nav bg-gradient flex-row  no-select">
		<a target="_blank" href="https://syn.bike" class="nav__logo">
			<IconLogo size="25px" />
		</a>
		<span class="nav__wordmark">synGeo</span>
		<div class="flex-1">
		</div>
		<router-link class="nav-link" to="/"><span>App</span></router-link>
		<router-link class="nav-link" to="/about"><span>About</span></router-link>
	</nav>
</template>
<script>
import IconLogo from "@/components/IconLogo.vue";
export default {
	name: 'Navbar',
	components: {
		IconLogo,
	}
}
</script>
<style>
.nav {
	height: 4rem;
	color: var(--color-font-primary);
	align-items: center;
}

.nav__wordmark {
	font-family: var(--font-alt);
	font-weight: 700;
	font-size: 1.7rem;
}

.nav__logo {
	display: flex;
	align-items: center;

	fill: var(--color-font-primary);
	padding: 5px 5px 5px 15px;
	margin-left: 1rem;
	margin-right: 1rem;
}

.nav-link {
	padding: .5rem 2rem;
	font-family: var(--font-alt)
}

.nav-link--title {
	font-size: 2rem;
	font-weight: 300;
}

.nav-link span {
	position: relative;
}

.nav-link span::after {
	content: '';
	position: absolute;
	top: 110%;
	left: 0;
	width: 0;
	height: .1rem;
	background-color: white;

	transition: width .3s ease;
}

.nav-link:hover span::after,
.nav-link:focus span::after {
	width: 100%;
}

.nav-link--title span::after {
	width: 100%;
}
</style>
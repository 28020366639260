<template>
	<div class="icon-logo" :style="{width: size, height: size, fill: fill}">
		<svg style="isolation:isolate" class="icon-logo__svg" viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
			<path d="M 185 45 C 185 58.807 173.807 70 160 70 C 100 70 80 97.484 80 117 C 80 148 120 165 160 165 C 171.046 165 180 173.954 180 185 C 180 196.046 171.046 205 160 205 C 154.907 205 150.258 203.096 146.727 199.961 L 146.712 200 C 120.833 179.649 73.616 151.472 44.69 140 L 44.888 139.871 C 36.119 135.988 30 127.208 30 117 C 30 105.441 37.845 95.714 48.501 92.853 C 77.249 80.892 119.729 57.111 139.758 30.326 C 144.302 24.069 151.676 20 160 20 C 173.807 20 185 31.193 185 45 Z" />
			<circle cx="60" cy="210" r="25" />
			<circle cx="240" cy="90" r="25" />
			<path d="M 140 137 C 128.954 137 120 128.046 120 117 C 120 105.954 128.954 97 140 97 C 143.889 97 147.519 98.11 150.589 100.03 L 150.598 100 C 169.947 112.55 221.074 146.084 254.55 161.889 C 263.619 165.641 270 174.575 270 185 C 270 195.714 263.26 204.854 253.789 208.411 L 253.889 208.481 C 219.634 220.033 183.199 246.558 158.677 271.618 C 154.098 276.761 147.427 280 140 280 C 126.193 280 115 268.807 115 255 C 115 241.193 126.193 230 140 230 C 183 230 220 217 220 185 C 220 143 165.493 137.014 140 137.014 L 140.004 137 C 140.003 137 140.001 137 140 137 Z" />
		</svg>
	</div>
</template>
<script>
export default {
	name: 'IconLogo',
	props: {
		size: String,
		fill: String,
	}
}
</script>
<style>
.icon-logo {
	height: 100%;
	width: 100%;
	stroke: inherit;
	background: transparent;
}

.icon-logo__svg {
	display: block;
	height: 100%;
	width: 100%;
}
</style>
'use strict';
import ResizeObserver from 'resize-observer-polyfill';

import { reactive, computed } from "vue";

export class KonvaStage {
	constructor(stageRef) {
		this.element = null;
		this.stageRef = stageRef;
		this.ro = null;
		this.wheelListener = null;

		this.config = reactive({
			width: 100,
			height: 100,
			draggable: true,
			background: '#DDDDDD'
		});
	}

	getStage() { return this.stageRef.value.getStage(); }

	makeResponsive() {
		this.config.width = this.element.clientWidth;
		this.config.height = this.element.clientHeight;
		this.ro = new ResizeObserver(entries => {
			for (let entry of entries) {
				const cr = entry.contentRect;
				this.config.width = cr.width;
				this.config.height = cr.height;
			}
		});
		this.ro.observe(this.element);

		this.getStage().position({
			x: this.config.width / 2,
			y: this.config.height / 2,
		})
	}

	addZoom() {
		const wheelListener = onZoom.bind(this.stageRef);
		this.wheelListener = wheelListener;
		this.element.addEventListener('wheel', wheelListener);
	}

	removeZoom() {
		this.element.removeEventListener('wheel', this.wheelListener);
		this.wheelListener = null;
	}

	stopKonva() {
		if (this.ro) { this.ro.disconnect(); }
		if (this.element) { this.element.removeEventListener('wheel', this.wheelListener); }
	}

	boxCenter(box, fillPercent = null) {
		//Top and Left are required. Width/Heigh OR Right/Bottom required
		//If right key is provide then calc width and height
		if ('right' in box) {
			box.width = box.right - box.left;
			box.height = box.bottom - box.top;
		}

		const stage = this.getStage();
		//Check aspect
		const itemAspect = box.width / box.height;
		const stageAspect = this.config.width / this.config.height;

		const centerX = box.left + box.width / 2;
		const centerY = box.top + box.height / 2;

		//Optional fill percentage to zoom too
		if (fillPercent) {
			let newScale = itemAspect > stageAspect ? this.config.width / box.width : this.config.height / box.height;
			newScale *= fillPercent;
			setScale(stage, newScale);
		}

		stage.position({
			x: this.config.width / 2 - centerX * stage.scaleX(),
			y: this.config.height / 2 - centerY * stage.scaleY(),
		});

		stage.batchDraw();
	}

	clickPos(e) {
		const stage = this.getStage();
		const pointer = stage.getPointerPosition();
		const pos = stage.position();
		const x = pointer.x - pos.x;
		const y = pointer.y - pos.y;
		const scale = stage.scale();

		return [x / scale.x, y / scale.y]
	}

	lineConfig(pointArray, color = 'grey', dash = false) {
		let stroke = 4;
		if (dash) {
			dash = [this.noScale(dash[0]), this.noScale(dash[1])];
			stroke = 3;
		}
		return {
			name: 'line',
			points: pointArray.flat(),
			fill: 'transparent',
			stroke: color,
			strokeWidth: this.noScale(stroke),
			closed: false,
			dash: dash ? dash : false,
			lineJoin: 'round',
			lineCap: 'round',
			listening: true,
		}
	}

	pointConfig(point, color = 'black') {
		return {
			name: 'point',
			x: point[0],
			y: point[1],
			radius: this.noScale(4),
			fill: color,
			stroke: color,
			strokeWidth: 0,
		}
	}

	wheelConfig(point, radius, color = 'black') {
		return {
			x: point[0],
			y: point[1],
			radius: radius,
			fill: 'transparent',
			stroke: color,
			strokeWidth: this.noScale(4),
		}
	}

	//Helper funtion to set initial config values such as stroke that will not scale
	noScale(num) {
		return num / this.getStage().scaleX();
	}
}


function setScale(stage, newScale) {
	// const factor = stage.scaleX() / newScale;
	const oldScale = stage.scaleX();
	stage.scale({ x: newScale, y: newScale });

	// Don't scale strokes
	stage.find('Shape').forEach((item) => {
		item.strokeWidth(item.strokeWidth() * oldScale / newScale);
		if (item.dash()) {
			item.dash([
				item.dash()[0] * oldScale / newScale,
				item.dash()[1] * oldScale / newScale
			]);
		}
	});

	stage.find('.point').forEach((item) => {
		item.radius(item.radius() * oldScale / newScale);
	});
}

//Handle stage zooming
function onZoom(e) {
	if (!e) { return; }
	const stage = this.value.getStage(); //From bound ref
	const scaleBy = 1.02; //Scale factor 
	const oldScale = stage.scaleX();

	const mousePointTo = {
		x: (stage.getPointerPosition().x - stage.x()) / oldScale,
		y: (stage.getPointerPosition().y - stage.y()) / oldScale
	};

	const newScale = e.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

	setScale(stage, newScale);

	const newPos = {
		x: -(mousePointTo.x * newScale - stage.getPointerPosition().x),
		y: -(mousePointTo.y * newScale - stage.getPointerPosition().y)
	};

	stage.position(newPos); //Move stage to pointer while zooming
	stage.batchDraw(); //Draw all
}
<template>
	<div class="input-table flex-column flex-1" :class="{'input-table--compact' : compact}" autocomplete="off" :data-test="tableName" :style="tableStyle">
		<div v-if="headerRow" class="input-table__header">
			<span v-for="(item, index) in headerRow" class="no-select" :key="'header-item-' + index" :style="{maxWidth : index === 0 ? labelWidth : cellWidth, minWidth : index === 0 ? labelWidth : cellWidth}" :class="{'input-row__cell': index !== 0, 'input-row__label--header' : index === 0}">
				<slot :name="'slot-' + index">{{item}}</slot>
			</span>
		</div>
		<template v-for="(row, indexRow) in tableSetup">
			<div v-if="row[2]" class="input-row" :key="'div-row-' + indexRow" :data-test="'table-row-' + indexRow">
				<span :key="'row-label-' + indexRow" class="input-row__label no-select" :style="{maxWidth : labelWidth, minWidth : labelWidth}">
					{{row[0]}}
				</span>
				<input type="text" v-for="(column, indexColumn) in row[1]" class="input-row__cell-input" :key="'inputCell-' + indexRow + '-' + indexColumn" :style="{maxWidth : cellWidth, minWidth : cellWidth}" :autocomplete="row[0]+indexColumn" :value="column" :disabled="row[3] || (row.length > 4 && row[4][indexColumn])" :aria-label="'Input table row ' + row[0] +' column ' + indexColumn" :data-test="indexRow + '-' + indexColumn" @blur="$emit('change', indexRow, indexColumn, $event.target.value.trim(), column)" @change.stop @keydown.enter="$emit('change', indexRow, indexColumn, $event.target.value.trim(), column)" @keydown.esc="$emit('change', indexRow, indexColumn, column, column)" @focus="$emit('focus', indexRow, indexColumn, column)">
			</div>
		</template>
		<!-- Option full width footer -->
		<div v-if="footer" class="input-footer">
			<span class="input-row__label" :style="{maxWidth : labelWidth, minWidth : labelWidth}"></span>
			<slot name="footer"></slot>
		</div>
	</div>
	<!-- Input array in ['row name', 'value array for row', 'show/hide row', 'disable input', 'option column disable'] -->
</template>
<script>
export default {
	name: 'InputTable',
	emits: ['change', 'focus'], // row, column, value, prev - output format
	props: {
		compact: Boolean,
		tableName: String,
		tableStyle: Object,
		headerRow: Array,
		labelWidth: String,
		cellWidth: String,
		tableSetup: Array,
		footer: Boolean,
	},
}
</script>
<style>
.input-table {
	position: relative;
	width: 100%;
	max-height: 100%;
	margin-bottom: .5rem;
	overflow: hidden;
	/*overflow-x: auto;*/
	overflow-y: auto;

	--input-table-height: 2.5rem;
}

.input-table--compact {
	--input-table-height: 2.25rem;
}

.input-row {
	display: flex;
	height: var(--input-table-height);

	color: var(--color-font-bg);
}

.input-table__header {
	position: sticky;
	top: 0;
	display: flex;
	z-index: 2;

	border-bottom: 0;
	font-weight: 700;

	background-color: var(--color-bg);
}

.input-footer {
	position: sticky;
	left: 0;
	bottom: 0;
	display: flex;
	padding: .5rem 0;
	z-index: 2;

	background-color: var(--color-bg);
}

.input-row__label {
	position: sticky;
	left: 0;
	min-width: 9rem;
	max-width: 9rem;
	min-height: var(--input-table-height);
	height: 100%;
	padding-right: 1rem;

	text-align: right;
	line-height: var(--input-table-height);

	z-index: 1;
}

.input-row__label--header {
	position: sticky;
	bottom: 0;
	left: 0;

	min-width: 9rem;
	max-width: 9rem;
	min-height: var(--input-table-height);
	padding-right: 1rem;

	text-align: right;
	line-height: 2.5rem;

	z-index: 3;
}

.input-row__cell {
	flex: 1;
	height: var(--input-table-height);
	min-width: 7rem;
	position: relative;
}

.input-row__cell-input {
	height: var(--input-table-height);
	min-width: 7rem;
	flex: 1;
	padding: .25rem .5rem;

	background-color: transparent;
	border: none;
	border-bottom: .1rem solid var(--color-bg-secondary-light);

	text-align: center;
}


.input-row__cell-input:focus {
	box-shadow: inset 0 0 0 1px var(--color-primary)
}

.input-row__cell-input:disabled {
	background-color: var(--color-bg-cell-highlight);
}

.input-table__header .input-row__cell:first-child {
	height: var(--input-table-height);
	min-width: 7rem;
	max-width: 7rem;
	margin-right: 1rem;

	border-bottom: none;

	line-height: var(--input-table-height);
	text-align: right;
}

.input-table__header .input-row__cell {
	flex: 1;

	border-bottom: .1rem solid var(--color-bg-secondary-light);

	line-height: 2.4rem;
}

@media(max-width: 500px) {
	.input-row__label {
		min-width: 10rem;
		max-width: 10rem;
	}
}
</style>
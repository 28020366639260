import { DefaultGeo } from "@/modules/bikeTemplate.js";
import { computeGeo, computeModGeo } from "@/modules/geometry.js";
import { copy } from "@/modules/utility.js";

import { reactive, readonly, ref, computed } from "vue";

const bikeList = reactive([]);
const selectedBike = ref(-1);
const maxBikes = 5;

const createBike = function() {
	console.log('Create a bike!')
	if (bikeList.length >= maxBikes) { return; }
	bikeList.push(copy(new DefaultGeo('Bike ' + bikeList.length)));
	selectedBike.value = bikeList.length - 1;

	bikeList[selectedBike.value].geometry = computeGeo(bikeList[selectedBike.value].geometry);
	bikeList[selectedBike.value].geo_mod = computeModGeo(bikeList[selectedBike.value].geometry,
		bikeList[selectedBike.value].modifiers);
}

const copyBike = function() {
	if (bikeList.length >= maxBikes) { return; }
	bikeList.push(copy(currentBike.value))
	selectedBike.value = bikeList.length - 1;

	bikeList[selectedBike.value].geometry = computeGeo(bikeList[selectedBike.value].geometry);
	bikeList[selectedBike.value].geo_mod = computeModGeo(bikeList[selectedBike.value].geometry,
		bikeList[selectedBike.value].modifiers);
}

const deleteBike = function(index) {
	bikeList.splice(index, 1);
	selectedBike.value = selectedBike.value < 1 ? 0 : selectedBike.value - 1;
}

const changeBike = function(index) {
	console.log('selected ' + index)
	selectedBike.value = index;
}

const setBikeData = function(payload) {
	const index = payload.index ? payload.index : selectedBike.value;
	if ('subItem' in payload) {
		bikeList[index][payload.item][payload.subItem] = payload.value;
	} else {
		bikeList[index][payload.item] = payload.value;
	}

	if (payload.item === 'geometry') {
		console.log('compute')
		bikeList[index].geometry = computeGeo(bikeList[index].geometry);
	}
	bikeList[index].geo_mod = computeModGeo(
		bikeList[index].geometry, bikeList[index].modifiers);
}

const bike = (index) => { return readonly(bikeList[index]); };
const currentBike = computed(() => { return bikeList[selectedBike.value]; })

createBike();
export default {
	deleteBike,
	createBike,
	copyBike,
	setBikeData,
	changeBike,

	bike, //return specific bike
	currentBike,
	bikeList: readonly(bikeList),
	selectedBike: readonly(selectedBike),
}
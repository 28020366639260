<template>
	<div class="flex-column flex-1 about-cont">
		<div class="about flex-1">
			<h1>About</h1>
			<p>This is a free tool for evaluating how bike changes such as cockpit adjustments, switching forks, and varying tire size impact the geometry. </p>
			<br>
			<h5>Opinionated</h5>
			<p>Because bicycle geometry can be driven from different sets of parameters some aspects of this software are opinionated. For example either reach or horizontal top tube can be the driving value to position the head tube. Similarly either BB height or BB drop can be used to the position the bottom bracket. We have selected the parameters we feel are best used to control mountain bike geometry. </p>
			<br>
			<h5>Accuracy</h5>
			<p>The geometry model used has been validated using automated testing with a commercial CAD engine. Due to the large volume of trigonometry used to evaluate the geometry some floating point error is inevitable. Our threshold for accuracy is 0.05mm (.002in). </p>
			<br>
			<p>Please remember that it is not uncommon for OEM manufacturers to slightly round the numbers in their published geometry tables. </p>
			<br>
			<p>As per the website <a :href="siteURL + '/terms'" target="_blank">terms and conditions</a> this tool is provide AS-IS without warranty of any kind. </p>
			<br>
			<h5>Definitions</h5>
			<ul>
				<li><b>Reach:</b> Horizontal distance from bottom bracket to center of head tube top.</li>
				<li><b>Total Reach:</b> Horizontal distance from bottom bracket to center of grips (ignoring bar sweep).</li>
				<li><b>Stack:</b> Vertical distance from bottom bracket to center of head tube top.</li>
				<li><b>Total Stack:</b> Vertical distance from bottom bracket to center of grips (ignoring bar sweep). Similar to the commonly used grip to ground measurement for comparing bar heights between bikes. Add bottom bracket height to get bar height from ground. </li>
				<li><b>Fork Length and Offset:</b> Fork axle to crown length and offset.</li>
				<li><b>Horizontal Top Tube (HTT):</b> Horizontal distance from center head tube top to the effective seat tube line. </li>
				<li><b>Trail: </b>Mechanical trail of the fork. Distance from the contact patch of the front tire to the intersection of an extension line of the steering axis and ground. We define positive trail as the instance when the contact patch sits rearward of the intersection point.</li>
				<li><b>Lower Headset:</b>Lower headset stack figure. Distance from fork crown to the bottom of the headtube along the steering axis. Accommodates thickness of lower headset cups and extended crown races. </li>
				<li><b>Front and Rear Center:</b> Horizontal distance from the bottom bracket to the front and rear axle.</li>
				<li><b>Chainstay Length:</b> Total distance from bottom bracket to rear axle (includes bottom bracket drop distance unlike rear center). </li>
				<li><b>Wheelbase: </b> Distance between tire contact patches.</li>
				<li><b>BB Drop:</b> Vertical distance between the rear axle and the bottom bracket. For bikes with a smaller rear wheel this means the front axle will be a different distance from the bottom bracket. </li>
				<li><b>BB Drop Total:</b> Bottom bracket distance from a line drawn between the two axles. </li>
				<li><b>BB Height:</b> Bottom bracket distance from ground.</li>
				<li><b>Seat Angle Effective: </b>Virtual seat angle used to position the saddle. The relation between effective and actual seat tube angles is dependent on the intersection point used. We use an intersection point on a line extending from the bottom bracket with a length equal to the Nominal Seat Height. For bikes with slacker actual seat tube angles this will position the saddle much closer to an equivalent bike with a straight seat tube. When the intersection point is positioned lower (such as level with the head tube) the saddle will deviate significantly from the intended position as the post extension is increased. </li>
				<li><b>Seat Angle Actual:</b> The actual angle of the seat tube with respect to the ground. </li>
				<li><b>Seat Tube Length: </b> The distance from the bottom bracket to the top of the actual seat tube. </li>
				<li><b>Nominal Seat Height:</b> Distance from the bottom bracket of the actual and effective seat tube intersection lines. This number is designed into the bicycle but would ideally be representative of the saddle heights used by a given bikes rider size range. </li>
				<li><b>Upper Headset: </b>Upper headset height as measured from the top of the head tube. </li>
				<li><b>Stem Length:</b> Length of the stem used. We assume all stems have an effective 20mm stack height (vertical distance from bottom of stem to center of bar along the steering axis). We also assume a stem angle of zero (we should probably fix this to better accommodate longer stems).</li>
				<li><b>Stem Spacers: </b>Total stem spacer stack in mm underneath the stem. </li>
				<li><b>Bar Height: </b>Bar rise figure. For all calculations we ignore bar sweep. </li>
				<li><b>Seated Fit: </b>Distance from saddle position (based on nom. saddle height), and the center of the bar grip. A good metric for estimating how bikes with varying reach numbers and seat tube angle will feel in a seated position. Somewhat similar to horizontal top tube but better estimates the impacts of saddle position change on seated position feel. </li>
			</ul>
			<br>
			<br>
			<footer>
				By using this app you agree to our <a :href="siteURL + '/privacy'" target="_blank">Privacy Policy</a> and <a :href="siteURL + '/terms'" target="_blank">Terms and Conditions</a>.
			</footer>
		</div>
	</div>
</template>
<style>
.about {
	max-height: 100%;
	max-width: 1000px;
	margin: auto;

	text-align: left;

}

.about-cont {
	overflow: hidden;
	padding: 1rem;
}

@media(min-width: 1000px) {
	.about-cont {
		padding: 3rem;
		overflow-y: auto;
	}
}


.about h1 {
	text-align: center;
}

.about p,
.about li {
	font-size: 1.5rem;
	line-height: 1.7
}

.about p a {
	font-size: 1.5rem;
	line-height: 1.7;
	text-decoration: underline;
}

.about li {
	margin: 1rem 0;

}
</style>
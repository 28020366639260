<template>
	<button class="btn-std" :class="{
			'btn-std--flex' : flex, 
			'btn-std--outline' : outline, 
			'btn-std--clear' : clear, 
			'btn-std--right' : right, 
			'btn-std--left' : left, 
			'btn-std--spinner' : spinner, 
			'btn-std--error' : error, 
			'btn-std--outline-error': error && outline, 
			'btn-std--clear-error': error && clear, 
			'btn-std--disabled' : disabled}" :style="btnstyle" :data-test="test">
		<slot>
			<span class="btn-std__text">{{name}}</span>
		</slot>
		<div class="btn-std__icon-cont">
			<slot name="icon-slot">
			</slot>
		</div>
	</button>
</template>
<script>
export default {
	name: 'ButtonStandard',
	props: {
		flex: Boolean,
		outline: Boolean,
		clear: Boolean,
		right: Boolean,
		left: Boolean,
		spinner: Boolean,
		error: Boolean,
		disabled: Boolean,
		btnstyle: Object,
		test: String,
		name: String,
	}
}
</script>
<style>
.btn-std {
	height: 3rem;
	min-height: 3rem;
	width: 10rem;
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	position: relative;

	color: var(--color-font-primary);
	background-color: var(--color-primary);
	border: none;
	border-radius: .3rem;
	cursor: pointer;

	font-family: var(--font-buttons);
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 1px;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;

	/*transition: .3s;*/
}

.btn-std__text {
	display: block;
	width: 100%;
	padding: 0 .5rem;
}

.btn-std--outline {
	color: var(--color-primary);
	border: 0.1rem solid var(--color-primary);
	background-color: var(--color-bg);
}

.btn-std--error {
	background-color: var(--color-error);
}

.btn-std--outline-error {
	color: var(--color-error);
	background-color: var(--color-bg);
	border-color: var(--color-error);
}

.btn-std--clear {
	border: none;
	color: var(--color-primary);
	background-color: transparent;
}

.btn-std--clear-error {
	color: var(--color-error);
}

.btn-std--disabled {
	background-color: var(--color-bg-secondary-light);
	cursor: default;
}

.btn-std:hover,
.btn-std:focus {
	box-shadow: 0 0 .1px 3px var(--color-primary-light);
	transition: .2s;
}

.btn-std--error:hover,
.btn-std--error:focus {
	box-shadow: 0 0 0 3px var(--color-error-light);
	transition: .2s;
}

/*.btn-std--disabled:hover,
.btn-std--disabled:focus {
	box-shadow: none;
}*/

.btn-std:active {
	box-shadow: none;
	transition: .1s;
}

.btn-std--left {
	margin-left: .5rem;
}

.btn-std--right {
	margin-right: .5rem;
}

.btn-std--flex {
	flex: 1;
	width: 100%;
}

.btn-std__icon-cont {
	position: absolute;
	width: 3rem;
	height: 100%;
	padding: .25rem;
	right: 0;
	top: 0;

	background-color: transparent;
	stroke: var(--color-font-primary);

	pointer-events: none;
}

.btn-std--error>.btn-std__icon-cont {
	stroke: var(--color-error);
}

.btn-std--clear>.btn-std__icon-cont,
.btn-std--outline>.btn-std__icon-cont {
	stroke: var(--color-primary);
}

.btn-std--spinner {
	position: relative;
}

@keyframes btn-std--spinner {
	to {
		transform: rotate(360deg);
	}
}

.btn-std--spinner:after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	top: 47%;
	right: 7px;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	margin-left: -10px;
	border-radius: 50%;
	border: 2px solid transparent;
	border-top-color: var(--color-font-primary);
	background-color: transparent;
	animation: btn-std--spinner .8s linear infinite;
	z-index: 51;
}

.btn-std--outline:after {
	border-top-color: var(--color-primary);
}

.btn-std-error:after {
	/*border-top-color: var(--color-error);*/
}

.btn-std--outline-error::after {
	border-top-color: var(--color-error);
}


.btn-std--clear:after {
	border-top-color: var(--color-primary);
}
</style>